
import {
  getOrderInfo,
  getAppId,
  getUserOpenId,
  payment,
  PaymentParamsType,
} from '@/api/payment';
import { getURLParamsByKey } from '@/utils/index';
import Vue from 'vue';
import Cookies from 'js-cookie';
interface OrderInfoType {
  status: string;
  downPaymentAmount: number;
  tailPaymentAmount: number;
}
export default Vue.extend({
  data() {
    return {
      status: '',
      spinning: false,
      orderInfo: {} as OrderInfoType,
      paymentInfo: {} as Partial<PaymentParamsType>,
    };
  },
  mounted() {
    document.title = '标书代做费';
    this.queryOrderInfo(this.$route.query?.id);
    this.getOpenId();
  },
  computed: {
    price(): number {
      const { status, downPaymentAmount, tailPaymentAmount } = this.orderInfo;
      return (
        (status === 'TO_BE_CONFIRMED'
          ? downPaymentAmount
          : tailPaymentAmount) ?? 0
      );
    },
    isToBeConfirmed(): boolean {
      return this.orderInfo?.status === 'TO_BE_CONFIRMED';
    },
  },
  methods: {
    async getOpenId() {
      let openId = Cookies.get('openId') ?? '';
      if (!openId) {
        const code = getURLParamsByKey('code');
        if (code) {
          openId = await getUserOpenId(code);
          Cookies.set('openId', openId, { expires: 0.2 });
          return openId;
        } else {
          const appId = await getAppId();
          const redirectUri = window.location.href;
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base#wechat_redirect`;
        }
      }
      return openId;
    },
    queryOrderInfo(id) {
      if (!id) {
        return;
      }
      this.spinning = true;
      getOrderInfo(id)
        .then((res) => {
          if (res) {
            this.orderInfo = res;
          } else {
            this.$message.warning('订单信息不存在');
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    async pay() {
      if (this.orderInfo.status === 'TAIL_PAYMENT') {
        this.$toast('该订单尾款已支付');
        return;
      }
      this.spinning = true;
      const openId = await this.getOpenId();
      const id = this.$route.query?.id;
      if (!id || !openId) {
        this.spinning = true;
        return;
      }
      const paymentInfo = await payment(id, openId);
      this.paymentInfo = paymentInfo;
      if (typeof window.WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener(
            'WeixinJSBridgeReady',
            this.onBridgeReady,
            false
          );
        } else if ((document as any).attachEvent) {
          (document as any).attachEvent(
            'WeixinJSBridgeReady',
            this.onBridgeReady
          );
          (document as any).attachEvent(
            'onWeixinJSBridgeReady',
            this.onBridgeReady
          );
        }
      } else {
        this.onBridgeReady();
      }
      // this.$router.push('/bidding');
    },
    onBridgeReady() {
      const {
        appId,
        timeStamp,
        nonceStr,
        signType,
        paySign,
        package: packageStr,
      } = this.paymentInfo;
      this.spinning = false;
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId, //公众号ID，由商户传入
          timeStamp, //时间戳，自1970年以来的秒数
          nonceStr, //随机串
          package: packageStr,
          signType, //微信签名方式：
          paySign, //微信签名
        },
        (res) => {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$message.success('支付成功');
            setTimeout(() => {
              this.$router.push('/bidding');
            }, 1000);
          } else {
            this.$message.error('支付失败');
          }
        }
      );
    },
  },
});
