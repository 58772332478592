/*
 * @Descripttion: 
 * @Author: renmingming
 * @Date: 2022-10-18 14:32:12
 * @LastEditors: renmingming
 * @LastEditTime: 2022-11-03 10:56:09
 */
import http from '@/utils/http';
interface OrderInfoType {
  status: string;
  downPaymentAmount: number;
  tailPaymentAmount: number;
}
/**
 * 订单详情
 * @param id 
 * @returns 
 */
export function getOrderInfo(id: string) {
  return http.get<OrderInfoType>(`order-resource/1/preOrderInfo/${id}`)
}

/**
 * 获取appId
 * @returns 
 */
export function getAppId() {
  return http.get('/agency-resource/1/wx/mp/appid')
}

/**
 * 获取用户openId
 * @param code 
 * @returns 
 */
export function getUserOpenId(code) {
  return http.get(`/agency-resource/1/wx/mp/openid?code=${code}`)
}

export interface PaymentParamsType {
  appId: string;
  nonceStr: string;
  package: string;
  paySign: string;
  signType: string;
  timeStamp: string;
}
/**
 * 支付
 * @param orderNo 
 * @param openId 
 * @returns 
 */
export function payment(orderNo, openId) {
  return http.get<PaymentParamsType>(`/order-resource/1/order/v2/${orderNo}/1/wx_pub_qr/5/pay/PREPAID/${openId}`)
}